import { json } from "@remix-run/node";
import { LiveReload, Outlet, Links, Scripts, useLoaderData, Meta, ScrollRestoration } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { useChangeLanguage } from "remix-i18next";
import { i18nCookie } from "./cookie";
import { ViewStateProvider } from "./context/ViewContext";
import { FormModalProvider } from "~/context/FormModalContext";
import { InfoModalProvider } from "~/context/InfoModalContext";
import { ThemeProvider, CssBaseline, createTheme } from "@mui/material";
import { useEffect } from "react";
import { esES, enUS, ptBR } from "@mui/material/locale";


import ServerError from "./components/shared/error/ServerError";
import globalStyles from "./styles/global.css";
import theme from "./common/Theme/theme";
import faviconAssetUrl from "./assets/favicon.ico";
import i18next from "~/i18next.server";
import dayjs from "dayjs";

import "dayjs/locale/es";
import "dayjs/locale/en";
import "dayjs/locale/pt";

import type { LinksFunction, LoaderArgs, MetaFunction } from "@remix-run/node";

export async function loader({ request }: LoaderArgs) {
  const locale = await i18next.getLocale(request);
  return json(
    { locale },
    {
      headers: { "Set-Cookie": await i18nCookie.serialize(locale) }
    }
  );
}

export default function App() {
  let { locale } = useLoaderData<typeof loader>();
  useEffect(() => {dayjs.locale(locale);}, [locale]);
  useChangeLanguage(locale);
  return (
    <Document>
      <ViewStateProvider>
        <FormModalProvider>
          <InfoModalProvider>
            <Outlet />
          </InfoModalProvider>
        </FormModalProvider>
      </ViewStateProvider>
    </Document>);

}

function Document({ children }: {children: React.ReactNode;}) {
  const { i18n } = useTranslation();
  const muiLocale = i18n.language === "es" ? esES : i18n.language === "en" ? enUS : ptBR;
  const muiTheme = createTheme(theme, muiLocale);
  return (
    <html lang={i18n.resolvedLanguage} dir={i18n.dir()}>
      <head>
        <Meta />
        <Links />
      </head>
      <body>
        <ThemeProvider theme={muiTheme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>);

}

export function ErrorBoundary({ error }: {error: any;}) {
  return (
    <html lang="es">
      <head>
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Links />
      </head>
      <body>
        <ServerError error={error} />
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>);


}

export let handle = {
  i18n: "common"
};

export const links: LinksFunction = () => {
  return [
  {
    rel: "icon",
    href: faviconAssetUrl,
    type: "image/x-icon"
  },
  {
    rel: "stylesheet",
    href: globalStyles
  }];

};

export const meta: MetaFunction = () => ({
  title: "Bretit"
});