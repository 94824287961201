import React from 'react';

const ErrorComponent = ({ error }: { error: any }) => {
    return (
        <main className="error-page">
            <div className='error-content'>
                <img src="server-error.jpg" alt="Error" className="error-icon" />
                <h1>Ocurrió un error</h1>
                <p>Lo sentimos, ha ocurrido un error en el servidor.</p>
                <pre>{error?.stack!}</pre>
                <p>Puedes intentar volver al <a href="/" className='error-link'>inicio</a>.</p>
            </div>
        </main>
    );
};

export default ErrorComponent;


